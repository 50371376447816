@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: DIN_Regular, Roboto Light, Arial, sans-serif;
}

.starlink-logo {
  width: 95px;
  height: 15px;
  background-image: url(https://www.starlink.com/assets/images/logo_white.png);
  background-size: cover;
}

.starlink-background {
  background-image: url(https://api.starlink.com/public-files/Module_1.jpg);
  background-size: cover;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 768px) {
  .starlink-logo {
    width: 148px;
    height: 20px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#right-header {
  width: 800px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  #personal-link,
  #business-link,
  #link-divider,
  #header-navigation-option,
  #roam,
  #boats,
  #left-stretch,
  #residential {
    display: none;
  }
  #background-navbar {
    background-color: none;
  }
  #right-header {
    width: 40px;
  }

  #orderView {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    right: 0;
  }

  #order-button,
  #orderButton {
    width: 100%;
  }

  #footer-allowance {
    display: none;
  }

  #starlink-footer {
    display: none;
  }
}
